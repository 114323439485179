<template>
  <div >
    <ul class="images">
      <li class="img"></li>
      <li class="img"></li>
      <li class="img"></li>
      <li class="img"></li>
      <li class="img"></li>
    </ul>
    <ul class="min">
      <li class="m" v-for="(item, index) in m" :key="index"></li>
    </ul>
    <div class="button">
      <div class="button-left" @click="slideLeft"></div>
      <div class="button-right" @click="slideRight"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      index: 0,
      m: [1, 2, 3, 4, 5],
      time: null
    };
  },
  mounted() {
    this.timer();
  },
  methods: {
    position() {
      const images = document.querySelector(".images");
      images.style.left = `${this.index * -100}%`;
    },
    add() {
      if (this.index >= this.m.length - 1) {
        this.index = 0;
      } else {
        this.index++;
      }
    },
    desc() {
      if (this.index < 1) {
        this.index = this.m.length - 1;
      } else {
        this.index--;
      }
    },
    timer() {
      this.time = setInterval(() => {
        this.index++;
        this.desc();
        this.add();
        this.position();
      }, 3000);
    },
    slideLeft() {
      this.desc();
      this.position();
      clearInterval(this.time);
      this.timer();
    },
    slideRight() {
      this.add();
      this.position();
      clearInterval(this.time);
      this.timer();
    }
  }
};
</script>

<style scoped>
/* 在这里写样式 */
* {
  /*padding: 0px;*/
  /*margin: 0px;*/
  /*list-style: none;*/
  /*bottom: 0;*/
  /*text-decoration: none;*/
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* 背景渐变 */
  background-image: linear-gradient(to bottom right,
  #91defe,
  #99c0f9,
  #bdb6ec,
  #d7b3e3,
  #efb3d5,
  #f9bccc);
}

.shell {
  width: 1200px;
  height: 700px;
  position: relative;
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 20px 30px 20px rgba(0, 0, 0, 0.25);
}
.images {
  width: 500%;
  height: 100%;
  display: flex;
  position: absolute;
  left: 0;
  transition: .2s;
}
.img {
  width: 100%;
  background-size: cover;
}
.img:nth-child(1) {
  background-image: url("http://file.eean.cn/xfm/xfm1.jpg");
}
.img:nth-child(2) {
  background-image: url("http://file.eean.cn/xfm/xfm2.jpg");
}
.img:nth-child(3) {
  background-image: url("http://file.eean.cn/xfm/xfm3.jpg");
}
.img:nth-child(4) {
  background-image: url("http://file.eean.cn/xfm/xfm4.jpg");
}
.img:nth-child(5) {
  background-image: url("http://file.eean.cn/xfm/xfm5.jpg");
}
.min {
  display: flex;
  justify-content: space-evenly;
  position: absolute;
  bottom: 40px;
  width: 30%;
  z-index: 999;
  left: 50%;
  transform: translateX(-50%);
}
.m {
  width: 20px;
  height: 20px;
  cursor: pointer;
  border-radius: 50%;
  border: solid rgba(255, 255, 255, 0.5) 5px;
  background-color: #fff;
}
.button {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: space-between;
  user-select: none;
}
.button-left,
.button-right {
  font-size: 50px;
  background-color: rgba(160, 193, 255, 0.2);
  padding: 0 20px;
  cursor: pointer;
  line-height: 700px;
  color: #fff;
}

</style>
